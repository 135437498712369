import React, { useState } from "react";
import API from "../utils/API";
import CsvDownloader from "react-csv-downloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";

const CSVDownlaod = ({ url, filename, filtration, columns }) => {
    const [csvLoading, setCsvLoading] = useState(false);

    const generateCSV = async () => {
        setCsvLoading(true);
        let response;
        filtration ? response = await API.post(url, filtration) :  response = await API.get(url);
        setCsvLoading(false);
        return Promise.resolve(response.data.data);
    };

    return (
        <>
            {!csvLoading ? (
                <CsvDownloader datas={generateCSV} filename={filename} columns={columns}>
                    <FontAwesomeIcon
                        className="align-middle cursor-pointer"
                        icon={faFileCsv}
                        style={{ fontSize: "20px" }}
                        fixedWidth
                    />
                </CsvDownloader>
            ) : (
                <Spinner style={{ width: "25px", height: "25px" }} />
            )}
        </>
    );
};

export default CSVDownlaod;
