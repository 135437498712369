import React from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "reactstrap";

const SummaryChart = ({ theme, inputData }) => {
    let labels = [], leads = [], revenue = []

    for (let index = inputData.length-1; index >= 0; --index) {
        const element = inputData[index];
        labels.push(element.date)
        leads.push(element.leads)
        revenue.push(element.total)
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Matched",
                fill: true,
                backgroundColor: "transparent",
                borderColor: theme.primary,
                data: leads
            },
            {
                label: "Revenue",
                fill: true,
                backgroundColor: "transparent",
                borderColor: theme.secondary,
                data: revenue
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        tooltips: {
            intersect: false
        },
        hover: {
            intersect: true
        },
        plugins: {
            filler: {
                propagate: false
            }
        },
        scales: {
            xAxes: [
                {
                    reverse: true,
                    gridLines: {
                        color: "rgba(0,0,0,0.05)"
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        stepSize: 500
                    },
                    display: true,
                    borderDash: [5, 5],
                    gridLines: {
                        color: "rgba(0,0,0,0)",
                        fontColor: "#fff"
                    }
                }
            ]
        }
    };

    return (
        <>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                    Total Matched Leads
                </CardTitle>
            </CardHeader>
            <CardBody>
                <div className="chart">
                    <Line data={data} options={options} />
                </div>
            </CardBody>
        </>
    );
};

export default connect(store => ({
    theme: store.theme.currentTheme
}))(SummaryChart);
