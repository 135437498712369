import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { logout } from "../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Settings,
  Key,
  User,
  Lock
} from "react-feather";
import AccountSelect from "./AccountSelect";

const NavbarComponent = ({ userData,dispatch }) => {

  let history = useHistory();

  const signOut = () => {
    dispatch(logout());
  }

  return (
      <Navbar color="white" light expand>
        <span
            className="sidebar-toggle d-flex mr-2"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
        >
          <i className="hamburger align-self-center" />
        </span>
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <AccountSelect/>
            <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle"/>
              </DropdownToggle>
            </span>
              <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{userData.user.name}</span>
              </DropdownToggle>
            </span>
              <DropdownMenu right>
                <DropdownItem onClick={() => history.push('/profile')}><User size={18}
                                                                             className="align-middle mr-2"/> Profile
                  settings</DropdownItem>
                <DropdownItem onClick={() => history.push('/profile/password')}><Lock size={18}
                                                                                      className="align-middle mr-2"/> Change
                  password</DropdownItem>
                <DropdownItem onClick={() => history.push('/profile/mfa')}><Key size={18}
                                                                                className="align-middle mr-2"/> Multi-Factor
                  Authentication</DropdownItem>
                <DropdownItem onClick={signOut}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
  );
};

export default connect(store => ({
  app: store.app,
  userData: store.user
}))(NavbarComponent);
