import React,{useState,useEffect} from "react";
import {
    Card,
    Container, Table, CardBody
} from "reactstrap";
import dateFormat from "dateformat";
import DateRange from "../../components/formfields/DateRange";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import ListStat from "../../components/ListStat";
import API from "../../utils/API";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Product from "../../components/fields/Product";
import CSVDownlaod from "../../components/CSVDownlaod";

const LeadsDetails = () => {
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 31),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")
    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate})
    const [leads, setLeads] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    const handleChange = (e) => {
        setFiltration({ ...filtration, [e.target.name]: e.target.value })
    };

    const getleads = () => {
        setLoading(true)

        API.post('/publisher/leadsdetails',filtration).then((response) => {
            setLeads(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });
    }

    useEffect(() => {
        getleads()
    }, [filtration])

    return (<Container fluid className="p-0">
        <div className="row justify-content-between">
            <div className="col-auto">
                <h3 className="h3 mb-3">Leads Details</h3>
            </div>
            <div className="col-auto">
            <CSVDownlaod
                    url="/publisher/leadsdetailscsv"
                    filename="LeadData"
                    filtration={filtration}
                    columns={[
                        {
                            id: 'id',
                            displayName: 'id',
                        },
                        {
                            id: 'date',
                            displayName: 'date',
                        },
                        {
                            id: 'status',
                            displayName: 'status',
                        },
                        {
                            id: 'product',
                            displayName: 'product',
                        },
                        {
                            id: 'revenue',
                            displayName: 'revenue',
                        },
                        {
                            id: 'subid',
                            displayName: 'subid',
                        },
                    ]}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-4 col-sm-12">
                <label>Date range</label>
                <DateRange
                    onChange={changeData}
                    name={'date'}
                    value={defaultDate}
                />
            </div>
        </div>
        <Card className="mt-4">
            {loading &&
                <Loader absolute={true}/>
            }
            {leads?.length > 0 ?
                <div className="table-responsive">
                    <ListStat data={data}/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Lead Id</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Product</th>
                            <th>Revenue</th>
                            <th>Sub Id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            leads.map((lead, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{lead.id}</td>
                                        <td>{lead.date}</td>
                                        <td>
                                            {lead.earnpub > 0 ?
                                                <span className="badge badge-success">Matched</span>
                                                :
                                                <span className="badge badge-secondary">Unmatched</span>
                                            }
                                        </td>
                                        <td><Product product={lead.product}/></td>
                                        <td><MoneyTotal money={lead.earnpub}/></td>
                                        <td>{lead.subid}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
                :
                !loading &&
                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
            }
        </Card>
        {(data?.page > 0 && data?.pages > 0) &&
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}/>
        }
    </Container>)
}

export default LeadsDetails;
